import { TConfirmationModal } from "Components/ConfirmationModal";
import { EnumAsideActionMenu } from "Enums";
import { TMDocumentCreationEnum, TMDocumentFormEnum, TMDocumentStatusEnum, TMDocumentTypeEnum } from "Models/Enums";
import { TMEnumTableIdentifier } from "Models/NetworkModels/TMGridService";
import { TEnumSigningProgress, TFilterGroupItem } from "Types";
import { GermesAgentConnection } from "Utils/GermesAgent";

export type TGlobalState = {
  agent?: GermesAgentConnection | null;
  allTypeDocumentViewModal: TAllTypeDocumentViewModal;
  appRegisterId?: string;
  confirmationModal: TConfirmationModal;
  documentStatistics?: TDocumentStatistics;
  events: TEvent[];
  minimizedModalDragging: boolean;
  minimizedModals: TMinimizedModal[];
  modal: {
    [key: string]: any;
  };
  parentElementKey: number;
  signingProgressModal: {
    activeStep?: null | TEnumSigningProgress;
    activeSteps?: TEnumSigningProgress[];
    activeStepState?: boolean;
    visible: boolean;
  };
  tableData?: {
    [key in TMEnumTableIdentifier]?: TTableData;
  };
};

export type TDocumentStatistics = { [key in TMDocumentTypeEnum]: Array<{ color: string; label: string; value: number }> };

export type TTableData = {
  filterFields?: TFilterGroupItem[];
  filters: {
    [key: string]: any;
    count?: number;
    isDrop?: boolean | undefined;
    references?: null | string | undefined;
    skip?: number;
    take: number;
  };
  filterSettings?: {
    visible: boolean;
  };
  quickFilterVisible: boolean;
  selectedFieldsOptions?: {
    [key: string]: any[];
  };
  selectedRowId?: string;
  tableIdentifier: TMEnumTableIdentifier;
  tableList: any[];
};

export type TEvent = {
  eventId: string;
  eventName: string;
  status: TEventStatus;
};

export type TEventStatus = "active" | "progress" | false;

export type TAllTypeDocumentViewModal = {
  caseId?: string;
  documentId: null | string;
  documentType: null | string | TMDocumentTypeEnum;
  documentViewIdentifier?: string; // it helps to diff hr order documents
  fetchPath?: string;
  hideElements?: EnumAsideActionMenu[];
  minimize?: boolean;
  visible: boolean;
};

export enum TMinimizedModalTypeEnum {
  DocumentView = "DocumentView",
  FileView = "FileView",
  FormModal = "FormModal",
}

export type TMinimizedModal =
  | {
      documentInfo: {
        caseId?: string;
        creationType: TMDocumentCreationEnum;
        documentDate: null | string;
        documentId: string;
        documentNumber: null | string;
        documentType: string;
        documentViewIdentifier: string;
        documentViewName: string;
        form: TMDocumentFormEnum;
        status: TMDocumentStatusEnum;
      };
      type: TMinimizedModalTypeEnum.DocumentView;
    }
  | {
      fileInfo: { fileId: string };
      type: TMinimizedModalTypeEnum.FileView;
    }
  | {
      formInfo: { documentType: TMDocumentTypeEnum };
      type: TMinimizedModalTypeEnum.FormModal;
    };

export enum All_FILTER_FIELDS_KEYS {
  ABSENCE_PERIOD = "absencePeriod",
  ACADEMIC_DEGREE = "academicDegreeIds",
  ADDITIONAL_CORRESPONDENT = "additionalCorrespondent",
  ADDITIONAL_INFO = "additionalInfo",
  AGE = "age",
  AGE_CHILDREN = "ageChildren",
  APPLICANT_ADDRESS = "applicantAddress",
  APPLICANT_DISTRICTS_IDS = "applicantDistrictsIds",
  APPLICANT_EMAIL = "applicantEmail",
  APPLICANT_FULL_NAME = "applicantFullName",
  APPLICANT_INFO = "applicantInfo",
  APPLICANT_REGIONS_IDS = "applicantRegionsIds",
  APPLICATION_SUBMISSION_TYPES_IDS = "applicationSubmissionTypesIds",
  APPLICATION_TYPE = "applicationType",
  AUTHORS_IDS = "authorsIds",
  BASE_NAME = "basisName",
  BASIC = "basic",
  BIRTH_DATE = "birthDate",
  BIRTH_DAY = "birthday",
  CANDIDATE_IDS = "candidateIds",
  CASE_NAME = "casesIds",
  CASE_NUMBER = "caseNumber",
  CHILDREN_AGE = "childrenAge",
  CHILDREN_NUMBER = "childrenNumber",
  CITIZENSHIP_IDS = "citizenshipIds",
  COMPLAINT_INFO = "complaint_info",
  CONTENT_OF_RESOLUTION = "contentOfResolution",
  CONTROL_POINT_CONTENT = "controlPointContent",
  CONTROL_POINT_NUMBER = "controlPointNumber",
  CONVICTION = "conviction",
  CONVICTION_REASON = "convictionReason",
  CORRESPONDENT_ADDITIONAL_INFO = "correspondentAdditionalInfo",
  CORRESPONDENT_INFO = "correspondentInformation",
  CORRESPONDENTS_IDS = "correspondentsIds",
  CORRESPONDENTS_INFO = "correspondentsInfo",
  COUNT_CHILDREN = "countChildren",
  COUNTRY = "country",
  COUNTRY_IDS = "countryIds",
  CREATE_DATE = "createDate",
  DEADLINE = "deadline",
  DEADLINE_CONTROL_POINT = "deadLineControlPoint",
  DEPARTMENT_ID = "departmentId",
  DEPARTMENT_IDS = "departmentIds",
  DEPARTMENT_NAME = "departmentName",
  DEPARTMENTS_IDS = "departmentsIds",
  DISTINCTIVE_MARKS_IDS = "distinctiveMarksIds",
  DISTRICT = "district",
  DISTRICT_IDS = "districtIds",
  DOC_NAME = "docName",
  DOCUMENT_DATE = "documentDate",
  DOCUMENT_INFO = "document_info",
  DOCUMENT_LANGUAGES_IDS = "documentLanguagesIds",
  DOCUMENT_NOMINATION = "name",
  DOCUMENT_NUMBER = "documentNumber",
  DOCUMENT_SUBJECTS_IDS = "documentSubjectsIds",
  DOCUMENT_VIEWS_IDS = "documentViewsIds",
  EDUCATION = "education",
  EDUCATION_AND_SCIENTIFIC_DEGREE = "educationAndScientificDegree",
  EDUCATION_DEGREE = "educationDegreesIds",
  EDUCATION_STEP = "educationStepsIds",
  EMPLOYEE_FULLNAME = "employeeFullName",
  EXECUTION_DEADLINE = "executionDeadline",
  EXECUTION_DEADLINE_ORDER = "executionDeadlineOrder",
  EXPERIENCE = "experience",
  FAMILY_STATUS = "familyStatusesIds",
  FAMILY_STATUS_IDS = "familyStatusIds",
  FATHERNAME = "fatherName",
  FOREIGN_LANGUAGE_PROFICIENCY = "foreignLanguageProficiencyIds",
  FROM_ABSENCE_PERIOD = "fromAbsencePeriod",
  FROM_AGE = "fromAge",
  FROM_AGE_CHILDREN = "fromAgeChildren",
  FROM_BIRTH_DATE = "fromBirthDate",
  FROM_BIRTH_DAY = "fromBirthday",
  FROM_BUSINESS_TIP_PERIOD = "fromBusinessTripPeriod",
  FROM_CHILDREN_AGE = "fromChildrenAge",
  FROM_CHILDREN_NUMBER = "fromChildrenNumber",
  FROM_COUNT_CHILDREN = "fromCountChildren",
  FROM_CREATE_DATE = "fromCreateDate",
  FROM_DEADLINE = "fromDeadline",
  FROM_DEADLINE_CONTROL_POINT = "fromDeadLineControlPoint",
  FROM_DOCUMENT_DATE = "fromDocumentDate",
  FROM_EXECUTION_DEADLINE_ORDER = "fromExecutionDeadlineOrder",
  FROM_EXPERIENCE = "fromExperience",
  FROM_GENERAL_WORK_EXPERIENCE = "fromGeneralWorkExperience",
  FROM_HIRED_DATE = "fromHiredDate",
  FROM_INCOMING_DATE = "fromIncomingDate",
  FROM_ON_PROBATION_PERIOD = "fromOnProbationPeriod",
  FROM_OUT_DATE = "fromOutDate",
  FROM_PROJECT_DATE = "fromProjectRegDate",
  FROM_RECEIVED_DATE = "fromReceivedDate",
  FROM_REG_DATE = "fromRegDate",
  FROM_SEND_DATE = "fromSendCaseDate",
  FROM_SIGN_DATE = "fromSignDate",
  FROM_WORKED_YEAR = "fromWorkedYear",
  GENDER = "gender",
  GENERAL_WORK_EXPERIENCE = "generalWorkExperience",
  HAVING_CRIMINAL_RECORD = "havingCriminalRecord",
  HIRED_DATE = "hiredDate",
  INCOMING_DATE = "incomingDate",
  INCOMING_NUMBER = "incomingNumber",
  INFORMATION_ABOUT_STATE_AWARDS = "informationAboutStateAwards",
  INSTRUCTION_INFORMATION = "instructionInformation",
  IS_COLLECTIVE_COMPLAINT = "isCollectiveComplaint",
  IS_DEPUTY = "isDeputy",
  LABOUR_ACTIVITY = "labourActivity",
  LANGUAGE_IDS = "languageIds",
  LANGUAGE_PROFICIENCY = "languageProficiency",
  LASTNAME = "lastName",
  MAIN_DETAILS = "mainDetails",
  MESSAGE_STATUSES = "messageStatuses",
  METHOD_OF_RECEIVINGS_IDS = "methodOfReceivingsIds",
  METHOD_OF_SENDINGS_IDS = "methodOfSendingsIds",
  MILITARY_RANK = "militaryRanksIds",
  MILITARY_RANK_IDS = "militaryRankIds",
  NAME = "firstName",
  NATIONALITY = "nationalitiesIds",
  NATIONALITY_IDS = "nationalityIds",
  NATIVE_LANGUAGE_IDS = "nativeLanguageIds",
  NUMBER = "number",
  ON_PROBATION = "onProbation",
  ON_PROBATION_PERIOD = "onProbationPeriod",
  OTHER_INFO = "otherInfo",
  OTHER_INFORMATION = "otherInformation",
  OUT_DATE = "outDate",
  OUT_NUMBER = "outNumber",
  PARTY_MEMBERSHIP = "partyMembershipsIds",
  PARTY_MEMBERSHIP_IDS = "partyMembershipIds",
  PENSIONER_IDS = "pensionerIds",
  PERFORMERS_IDS = "performersIds",
  POST_ID = "postId",
  POST_IDS = "postIds",
  POST_NAME = "postName",
  PRIVATE_DETAILS = "privateDetails",
  PROJECT_DATE = "projectDate",
  PROJECT_NUMBER = "projectNumber",
  PURPOSE = "purpose",
  RECEIVED_DATE = "receivedDate",
  RECEPTION_STAFFS_IDS = "receptionStaffsIds",
  RECIPIENTS_IDS = "recipientsIds",
  REG_DATE = "regDate",
  REG_NUMBER = "regNumber",
  REGION = "region",
  REGION_IDS = "regionIds",
  REPLACEMENT_STAFF_ID = "replacementStaffIds",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  REQUEST_NUMBER = "number",
  REQUEST_STATUS = "statuses",
  REQUISITES = "requisites",
  RESOLUTION_INFO = "resolutionInfo",
  RESPONSIBLE_EXECUTOR_OF_APPEAL = "responsibleExecutorOfAppeal",
  RESPONSIBLE_PHONE_NUMBER = "responsiblePhoneNumber",
  RESPONSIBLE_STAFFS = "responsibleStaffsIds",
  RETIRE_STATUS = "pensionersIds",
  ROLE_ID = "roleId",
  SCIENTIFIC_ACTIVITY_IDS = "scientificActivityIds",
  SEARCH_TEXT = "searchText",
  SECRECY_LEVELS_IDS = "secrecyLevelsIds",
  SEND_CASE_DATE = "sendCaseDate",
  SHORT_CONTENT = "shortContent",
  SIGN_DATE = "signDate",
  SIGNER = "signer",
  SIGNER_IDS = "signerIds",
  SIGNERS_IDS = "signersIds",
  STAFF_FULLNAME = "staffIds",
  STAFF_INFO = "staffInfo",
  STATE_AWARDS = "stateAwardsIds",
  STATUS = "status",
  STATUS_DOCUMENT_IN_CASE = "status_document_in_case",
  STEP_IDS = "stepIds",
  TABLE_NUMBER = "tableNumber",
  TO_ABSENCE_PERIOD = "toAbsencePeriod",
  TO_AGE = "toAge",
  TO_AGE_CHILDREN = "toAgeChildren",
  TO_BIRTH_DATE = "toBirthDate",
  TO_BIRTH_DAY = "toBirthday", 
  TO_BUSINESS_TIP_PERIOD = "toBusinessTripPeriod",
  TO_CHILDREN_AGE = "toChildrenAge",
  TO_CHILDREN_NUMBER = "toChildrenNumber",
  TO_COUNT_CHILDREN = "toCountChildren",
  TO_CREATE_DATE = "toCreateDate",
  TO_DEADLINE = "toDeadline",
  TO_DEADLINE_CONTROL_POINT = "toDeadLineControlPoint",
  TO_DOCUMENT_DATE = "toDocumentDate", 
  TO_EXECUTION_DEADLINE_ORDER = "toExecutionDeadlineOrder",
  TO_EXPERIENCE = "toExperience",
  TO_GENERAL_WORK_EXPERIENCE = "toGeneralWorkExperience",
  TO_HIRED_DATE = "toHiredDate",
  TO_INCOMING_DATE = "toIncomingDate",
  TO_ON_PROBATION_PERIOD = "toOnProbationPeriod",
  TO_OUT_DATE = "toOutDate",
  TO_PROJECT_DATE = "toProjectRegDate",
  TO_RECEIVED_DATE = "toReceivedDate",
  TO_REG_DATE = "toRegDate",
  TO_SEND_DATE = "toSendCaseDate",
  TO_SIGN_DATE = "toSignDate",
  TO_WORKED_YEAR = "toWorkedYear",
  TRIP_ORGANIZATION_NAME = "tripOrganizationName",
  TYPE_OF_APPEALS = "typeOfAppeals",
  TYPES = "types",
  UNDER_PRESIDENTS_CONTROL = "underPresidentsControl",
  URGENCY_CATEGORIES_IDS = "urgencyCategoriesIds",
  WORK_POSITION = "postsIds",
  WORKED_YEAR = "workedYear",
}

export const ALL_DATE_FORMATS: string[] = [
  All_FILTER_FIELDS_KEYS.ABSENCE_PERIOD,
  All_FILTER_FIELDS_KEYS.RECEIVED_DATE,
  All_FILTER_FIELDS_KEYS.DEADLINE_CONTROL_POINT,
  All_FILTER_FIELDS_KEYS.DEADLINE,
  All_FILTER_FIELDS_KEYS.OUT_DATE,
  All_FILTER_FIELDS_KEYS.REG_DATE,
  All_FILTER_FIELDS_KEYS.PROJECT_DATE,
  All_FILTER_FIELDS_KEYS.INCOMING_DATE,
  All_FILTER_FIELDS_KEYS.DOCUMENT_DATE,
  All_FILTER_FIELDS_KEYS.CREATE_DATE,
  All_FILTER_FIELDS_KEYS.TO_SEND_DATE,
  All_FILTER_FIELDS_KEYS.AGE,
  All_FILTER_FIELDS_KEYS.BIRTH_DATE,
  All_FILTER_FIELDS_KEYS.CHILDREN_AGE,
  All_FILTER_FIELDS_KEYS.CHILDREN_NUMBER,
  All_FILTER_FIELDS_KEYS.GENERAL_WORK_EXPERIENCE,
  All_FILTER_FIELDS_KEYS.HIRED_DATE,
  All_FILTER_FIELDS_KEYS.ON_PROBATION_PERIOD,
  All_FILTER_FIELDS_KEYS.WORKED_YEAR,
];

export const ALL_DATE_CHILD_FORMATS: string[] = [
  All_FILTER_FIELDS_KEYS.FROM_BUSINESS_TIP_PERIOD,
  All_FILTER_FIELDS_KEYS.TO_BUSINESS_TIP_PERIOD,
  All_FILTER_FIELDS_KEYS.FROM_SIGN_DATE,
  All_FILTER_FIELDS_KEYS.TO_SIGN_DATE,
  All_FILTER_FIELDS_KEYS.FROM_RECEIVED_DATE,
  All_FILTER_FIELDS_KEYS.TO_RECEIVED_DATE,
  All_FILTER_FIELDS_KEYS.FROM_DEADLINE_CONTROL_POINT,
  All_FILTER_FIELDS_KEYS.TO_DEADLINE_CONTROL_POINT,
  All_FILTER_FIELDS_KEYS.FROM_DEADLINE,
  All_FILTER_FIELDS_KEYS.TO_DEADLINE,
  All_FILTER_FIELDS_KEYS.FROM_OUT_DATE,
  All_FILTER_FIELDS_KEYS.TO_OUT_DATE,
  All_FILTER_FIELDS_KEYS.FROM_REG_DATE,
  All_FILTER_FIELDS_KEYS.TO_REG_DATE,
  All_FILTER_FIELDS_KEYS.FROM_PROJECT_DATE,
  All_FILTER_FIELDS_KEYS.TO_PROJECT_DATE,
  All_FILTER_FIELDS_KEYS.FROM_INCOMING_DATE,
  All_FILTER_FIELDS_KEYS.TO_INCOMING_DATE,
  All_FILTER_FIELDS_KEYS.FROM_DOCUMENT_DATE,
  All_FILTER_FIELDS_KEYS.TO_DOCUMENT_DATE,
  All_FILTER_FIELDS_KEYS.FROM_CREATE_DATE,
  All_FILTER_FIELDS_KEYS.TO_CREATE_DATE,
  All_FILTER_FIELDS_KEYS.FROM_SEND_DATE,
  All_FILTER_FIELDS_KEYS.SEND_CASE_DATE,
  All_FILTER_FIELDS_KEYS.FROM_BIRTH_DATE,
  All_FILTER_FIELDS_KEYS.TO_BIRTH_DATE,
  All_FILTER_FIELDS_KEYS.FROM_CHILDREN_AGE,
  All_FILTER_FIELDS_KEYS.TO_CHILDREN_AGE,
  All_FILTER_FIELDS_KEYS.FROM_HIRED_DATE,
  All_FILTER_FIELDS_KEYS.TO_HIRED_DATE,
  All_FILTER_FIELDS_KEYS.FROM_GENERAL_WORK_EXPERIENCE,
  All_FILTER_FIELDS_KEYS.TO_GENERAL_WORK_EXPERIENCE,
  All_FILTER_FIELDS_KEYS.FROM_WORKED_YEAR,
  All_FILTER_FIELDS_KEYS.TO_WORKED_YEAR,
  All_FILTER_FIELDS_KEYS.FROM_ON_PROBATION_PERIOD,
  All_FILTER_FIELDS_KEYS.TO_ON_PROBATION_PERIOD,
  All_FILTER_FIELDS_KEYS.FROM_CHILDREN_NUMBER,
  All_FILTER_FIELDS_KEYS.TO_CHILDREN_NUMBER,
];
